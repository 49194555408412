// Importing components and necessary files.
import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../../userContext';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import SideMenu from '../../small-components/side-menu/side-menu';
import AccountWindow from '../account-window/account-window';
import EventForm from '../../small-components/event-form/event-form';
import './calendar-page.css';

/**
 * CalendarPage component.
 * This components represents the calendar page of the application.
 *
 * @returns {JSX.Element} The rendered calendar page component.
 */
function CalendarPage({ username }) {
  // State variables for controlling the visibility of the side menu and week view, and the selected week.
  const [isSideMenuOpen] = useState(false);
  const [isWeekViewOpen, setIsWeekViewOpen] = useState(false);
  // State variables for the events and event form.
  const [events, setEvents] = useState([]);
  const [isEventFormOpen, setIsEventFormOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  // Access the user value from the UserContext
  const { user } = useContext(UserContext);

  // Fetch the user's events when they log in.
  useEffect(() => {
    async function fetchEvents() {
      const response = await fetch(`/api/calendar?username=${user.username}`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });

      if (!response.ok) {
        console.error('Failed to fetch events');
        return;
      }

      const fetchedEvents = await response.json();
      setEvents(fetchedEvents);
    }

    if (user) {
      fetchEvents();
    }
  }, [user]);

  /**
   * Handles the click event for the week view button.
   * Sets the isWeekViewOpen state variable to true.
   */
  function handleWeekClick() {
    setIsWeekViewOpen(true);
  }

  /**
   * Closes the week view.
   * Sets the isWeekViewOpen state variable to false.
   */
  function closeWeekView() {
    setIsWeekViewOpen(false);
  }

  /**
   * Handles the click event for the add event button.
   */
  function handleAddEventClick() {
    setIsEventFormOpen(true);
  }

  /**
   * Handles the submission for the event form. 
   *
   * @param {*} event 
   */
  function handleEventFormSubmit(event) {
    setIsEventFormOpen(false);
    setEvents([...events, event]);
  }

  function handleEventClick(info) {
    // Show the event details in an alert.
    alert(`Event: ${info.event.title}\nStart: ${info.event.start}\nEnd: ${info.event.end}`);
  
    // Ask the user if they want to remove the event.
    if (window.confirm('Do you want to remove this event?')) {
      info.event.remove();
    }
  }

  return (
    <div className={`calendar-page ${isWeekViewOpen || isSideMenuOpen || isEventFormOpen ? 'blur' : ''}`}>

      {/* Display the side menu. */}
      <SideMenu />

      {/* Display the account window. */}
      <AccountWindow />

      {/* FullCalendar component for the month view. */}
      <div className={`calendar-container ${isSideMenuOpen ? 'blur' : ''}`}>
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          // Set the first day of the week to Monday.
          firstDay={1}
          // Display week numbers.
          weekNumbers={true}
          // Pass the events state variable to the FullCalendar component.
          events={events} 
          // Update the selected date when a date is clicked.
          dateClick={(info) => setSelectedDate(info.dateStr)}
          eventClick={handleEventClick}
          customButtons={{
            customWeekView: {
              text: 'Week View',
              // Handle the click event for the week view button.
              click: handleWeekClick
            },
            addEventButton: {
              text: 'Add Event',
              // Handle the click event for the add event button.
              click: handleAddEventClick
            }
          }}
          height={"75vh"}
          headerToolbar={{
            start: 'addEventButton prev',
            center: 'title',
            end: 'next customWeekView'
          }}
        />
      </div>

      {/* FullCalendar component for the week view. */}
      {isWeekViewOpen && (
        <div className="week-view-container">
          <div className="week-view">
            <FullCalendar
              plugins={[timeGridPlugin]}
              initialView="timeGridWeek"
              // Set the first day of the week to Monday.
              firstDay={1}
              events={events}
              headerToolbar={{
                left: 'prev,next',
                center: 'title',
                right: 'timeGridWeek,timeGridDay closeWeekViewButton'
              }}
              customButtons={{
                closeWeekViewButton: {
                  text: 'X',
                  // Handle the click event for the close week view button.
                  click: closeWeekView
                }
              }}
            />
          </div>  
        </div>
      )}

      {/* Display the event form when the "Add Event" button is clicked. */}
      {isEventFormOpen && (
        <EventForm
          selectedDate={selectedDate}
          onSubmit={handleEventFormSubmit}
          onClose={() => setIsEventFormOpen(false)}
          username={user}
        />
      )}

      {/* Footer. */}
      <div className="footer">
        {/* Footer content here. */}
      </div>
    </div>
  );
}

export default CalendarPage;