// Imports.
import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../../userContext';
import InventoryItem from '../inventory-item/inventory-item';
import './inventory-list.css';

/**
 * InventoryList component. This component fetches and displays a list of inventory items.
 * It also provides functionality to add, update, and remove items.
 */
function InventoryList() {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      setIsLoading(true);
      setError(null);
      fetch(`/api/items?username=${user.username}`)
        .then(response => {
          if (!response.ok) throw new Error('Failed to fetch items');
          return response.json();
        })
        .then(data => setItems(data))
        .catch(error => {
          setError(error.message);
          console.error('Error:', error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [user]);

  if (isLoading) return <div>Loading items...</div>;
  if (error) return <div>Error: {error}</div>;

  /**
   * Handler for adding a new item.
   */
  const handleAddItem = () => {
    const newItem = {
      name: '',
      serialNumber: '',
      stock: 0,
      pricePerUnit: 0,
    };
  
    // Add the new item to the items state
    setItems(prevItems => [...prevItems, newItem]);
  };

  /**
   * Handler for updating an item.
   * @param {number} index - The index of the item to update.
   * @param {object} updatedItem - The updated item.
   */
  // handleUpdateItem should be used in production.
  // eslint-disable-next-line no-unused-vars
  const handleUpdateItem = (index, updatedItem) => {
    // Update the item in the items state
    setItems(prevItems => {
      const newItems = [...prevItems];
      newItems[index] = updatedItem;
      return newItems;
    });
  
    // Send the updated item to the server
    fetch(`/api/inventory?username=${user.username}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      },
      body: JSON.stringify(updatedItem),
    })
      .then(response => response.json())
      .then(data => {
        // Update the item in the items state with the data from the server
        setItems(prevItems => {
          const newItems = [...prevItems];
          newItems[index] = data;
          return newItems;
        });
      })
      .catch(error => {
        console.error('Error updating item:', error);
        window.alert('There was a problem updating your item. Please try again.');
      });
  };

  /**
   * Handler for removing an item.
   * @param {number} index - The index of the item to remove.
   */
  const handleRemoveItem = index => {
    if (!user) {
      console.error('No user logged in');
      window.alert('Please log in to remove items');
      return;
    }

    const item = items[index];

    // Send a request to the server to remove the item
    fetch(`/api/inventory/${item.id}?username=${user.username}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    })
      .then(() => {
        // Remove the item from the items state
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
      })
      .catch(error => {
        console.error('Error removing item:', error);
        window.alert('There was a problem removing your item. Please try again.');
      });
  };

  return (
    <div className="inventory-list">
      <div className="inventory-item-header">
        <span className="item-header-name">Name</span>
        <span className="serial-number">Serial Number</span>
        <span className="item-description">Description</span>
        <span className="stock">Stock</span>
        <span className="price-per-unit">Price per Unit</span>
      </div>
      {/* Map over the items and render an InventoryItem component for each one */}
      {items.map((item, index) => (
        <InventoryItem key={index} item={item} onRemove={() => handleRemoveItem(index)} />
      ))}
      {/* Add item button */}
      <button className="add-item-button" onClick={handleAddItem}>Add Item</button>
    </div>
  );
}

export default InventoryList;