//Imports.
import React, { useState } from 'react';
import './create-account-form.css';

/**
 * CreateAccountForm component.
 *
 * @param {Object} props - Component props.
 * @param {Function} props.onBack - Function to call when the back button is clicked.
 * @returns {JSX.Element} The rendered JSX element.
 */
function CreateAccountForm({ onBack }) {
  // State variables for the form inputs.
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  
  /**
   * Handles the form submission.
   *
   * Validates the form inputs and sends a POST request to the server.
   * @param {Event} event - The form submit event.
   */
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate username
    if (username.length < 4 || !/^[a-zA-Z0-9]+$/.test(username)) {
      alert('Username must be at least 4 characters long and can only contain a-z, A-Z, and 0-9');
      return;
    }

    // Validate password
    if (
      password.length < 8 ||
      !/[A-Z]/.test(password) ||
      !/[0-9]/.test(password) ||
      !/[!@#$%^&*]/.test(password)
    ) {
      alert('Password must be at least 8 characters and contain at least one capital letter, one number, and one special character (!@#$%^&*)');
      return;
    }

    // Send data to the server
    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: username,
          password: password
        })
      });
  
      if (!response.ok) {
        alert('Failed to create account');
        return;
      }
    } catch (error) {
      alert(error.message);
      return;
    }
    
    // If everything is ok, notify user.
    alert('Account created successfully');
    onBack();
  };
  
  // Render the form.
  return (
    <form onSubmit={handleSubmit}>
      <label>
        Choose a username:
        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
      </label>
      <label>
       Choose a password:
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
      </label>
      <button type="submit">Create account</button>
      <button type="button" onClick={onBack}>Back</button>
    </form>
  );
}
  
  export default CreateAccountForm;