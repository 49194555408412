import React, { useState } from 'react';
import { UserContext } from './userContext'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import StartPage from './components/composed-components/start-page/start-page';
import CalendarPage from './components/composed-components/calendar-page/calendar-page';
import InventoryPage from './components/composed-components/inventory-page/inventory-page';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

function App() {
  // Initialize user state
  const [user, setUser] = useState(null);

  return (
    <React.StrictMode>
      <UserContext.Provider value={{ user, setUser }}>
        <Router>
          <Routes>
            <Route path="/calendar" element={<CalendarPage />} />
            <Route path="/Inventory" element={<InventoryPage />} />
            <Route path="/" element={<StartPage />} />
          </Routes>
        </Router>
      </UserContext.Provider>
    </React.StrictMode>
  );
}

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
