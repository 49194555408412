//Imports.
import React, { useState, useContext } from 'react';
import { UserContext } from '../../../userContext';
import './event-form.css';

/**
 * EventForm component for adding new events.
 *
 * @param {Object} props - The props object.
 * @param {string} props.selectedDate - The selected date.
 * @param {Function} props.onSubmit - The function to call when the form is submitted.
 * @param {Function} props.onClose - The function to call when the form is closed.
 * @param {string} props.username - The username of the logged-in user.
 */
function EventForm({ selectedDate, onSubmit, onClose, username }) {
  //State variables for the date, time, and note.
  const [date, setDate] = useState(selectedDate || '');
  const [time, setTime] = useState('');
  const [stopTime, setStopTime] = useState('');
  const [note, setNote] = useState('');
  // Access the user state from the UserContext
  const { user } = useContext(UserContext);

  /**
   * Handles the form submission.
   *
   * @param {Event} e - The form submission event.
   */
  async function handleSubmit(e) {
    e.preventDefault();

    // Create a new event with the date, time, stop time and note.
    const event = {
      title: note,
      start: `${date}T${time}`,
      end: `${date}T${stopTime}`,
      username: user.username
    };

    // Post the event to the server.
    const response = await fetch('/api/calendar', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      },
      body: JSON.stringify(event)
    });

    // If the response is not ok, log an error and return.
    if (!response.ok) {
      console.error('Failed to post event');
      return;
    }

    const savedEvent = await response.json();

    // Call the onSubmit prop with the saved event.
    onSubmit(savedEvent);

    // Clear the form.
    setDate('');
    setTime('');
    setStopTime('');
    setNote('');
  }

  return (
    <div className="event-form-container">
      <div className="event-form">
        <form onSubmit={handleSubmit}>
          <label>
            Date:
            <input type="date" value={date} onChange={e => setDate(e.target.value)} required />
          </label>
          <label>
            Time:
            <input type="time" value={time} onChange={e => setTime(e.target.value)} required />
          </label>
          <label>
          Stop Time:
          <input type="time" value={stopTime} onChange={e => setStopTime(e.target.value)} required />
          </label>
          <label>
            Note:
            <textarea value={note} onChange={e => setNote(e.target.value)} required />
          </label>
          <button type="submit">Add Event</button>
          <button type="button" onClick={onClose}>Cancel</button>
        </form>
      </div>
    </div>
  );
}

export default EventForm;