//Imports.
import React, { useState, useContext } from 'react';
import { UserContext } from '../../../userContext';
import './login-form.css';

/**
 * LoginForm component handles user login.
 *
 * @param {Object} props - The component's props.
 * @param {Function} props.onLogin - Callback function to handle login.
 * @param {Function} props.onForgot - Callback function to handle forgotten password/username.
 * @param {Function} props.onCreateAccount - Callback function to handle account creation.
 */
function LoginForm({ onLogin, onForgot, onCreateAccount }) {
  // eslint-disable-next-line no-unused-vars
  const { user, setUser } = useContext(UserContext);
  // State variables for username and password.
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  
  /**
   * Handles form submission.
   *
   * @param {Event} event - The form submission event.
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Validate input
    if (username.length < 4) {
      alert('Username must be at least 4 characters long');
      return;
    }

    if (password.length < 8) {
      alert('Password must be at least 8 characters long');
      return;
    }

    // Send login request
    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          loginUsername: username,
          loginPassword: password,
        }),
      });
      
      // If response is not ok, throw an error.
      if (!response.ok) {
        throw new Error('Failed to login');
      }
  
      // Parse response data as JSON and pass it to onLogin callback.
      const data = await response.json();
      onLogin(data);

      // Update the user state with the logged-in user's data.
      setUser(data);
    } catch (error) {
      // If an error occurs, show an alert with the error message.
      alert(error.message);

      // Reset the username and password fields.
      setUsername('');
      setPassword('');
      return;
    }
  };
  
  // Render the form.
  return (
    <form onSubmit={handleSubmit}>
      <label>
        Username:
        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
      </label>
      <label>
        Password:
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
      </label>
      <button type="button" className="link-button" onClick={onForgot}>Forgot password/username?</button>
      <button type="submit">Log in</button>
      <button type="button" onClick={onCreateAccount}>Create new account</button>
    </form>
  );
}
  
  export default LoginForm;